<template>
  <div style="color:white;">
    <br><br>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <img :src="'./ankh_logo_color.svg'" />
      </div>
      <div class="col-4 text-right">
        <div class="row padonly">
          <div class="col-5 text-right">
            <!--<span>分店</span>-->
          </div>
          <div class="col-7 text-right" style="color:black;">
            <Multiselect
              v-model="branch"
              mode="single"
              :close-on-select="true"
              :searchable="false"
              :create-option="false"
              :options="branchList"
              :multiple="false"
              :allow-empty="false"
              :canClear="false"
              :canDeselect="false"
              valueProp="branch"
              trackBy="branch"
              label="branch"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="phoneonly">
      <br>
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6 text-center" style="color:black;">
          <Multiselect
            v-model="branch"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :create-option="false"
            :options="branchList"
            :multiple="false"
            :allow-empty="false"
            :canClear="false"
            :canDeselect="false"
            valueProp="branch"
            trackBy="branch"
            label="branch"
          />
        </div>
        <div class="col-3"></div>
      </div>
     </div> 
    <br>
    <hr />
    <br>
    <div class="row">
      <div class="col-3">
        <b-button :href="'./#/eForm/form_application/'+branch" class="mx-1 menu_btn" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'user']"/>
        </b-button>
        <br>
        <span class="font_style">會員表格</span>
      </div>
      <div class="col-3">
        <b-button href="./#/Dashboard" class="mx-1 menu_btn" variant="outline-light">
        <font-awesome-icon :icon="['fas', 'user']"/></b-button>
        <br>
        <span class="font_style">內部表格</span>
      </div>
      <div class="col-3">
        <b-button :href="'./#/booking/listing/'+branch" class="mx-1 menu_btn" variant="outline-light">
        <font-awesome-icon :icon="['fas', 'calendar-day']"/></b-button>
        <br>
        <span class="font_style">預約表</span>
      </div>
      <div class="col-3">
        <!-- notification/shop -->
        <b-button href="./#/Dashboard" class="mx-1 menu_btn position-relative" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'bell']"/>
            <b-badge variant="danger" text-indicator style="transform: translate(0%, 0%) !important; left:80% !important; border-radius: 1rem !important; font-size: 1rem !important;">
              0
            </b-badge>
        </b-button>
        <br>
        <span class="font_style">通告</span>
      </div>
    </div>
    <br><br><br>
    <div class="row">
      <div class="col-3">
        <b-button :href="'./#/setting/AssignRole'" class="mx-1 menu_btn" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'wrench']"/>
        </b-button>
        <br>
        <span class="font_style">設定員工權限</span>
      </div>
      <div class="col-3">
      </div>
      <div class="col-3">
      </div>
      <div class="col-3">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      lang: '',
      branch: '',
      branch_list: [],
    }
  },
  computed: {
    branchList: function () {
      var self = this
      return self.branch_list.filter(function (item) {
        return item.id != 6
      })
    }
  },
  created() {
    var self = this
    self.initialize()
    self.get_api()
    self.lang = localStorage.getItem('locale')
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
  },
  methods: {
    initialize() {

    },
    get_api() {
      var self = this
      this.axios
      .get('/master/branch', {})
      .then(function (response) {
        self.branch_list = response.data.data
        //self.get_filter()

        //System prepare
        self.axios
        .get('/system/prepare/booking', {})
        .then(function (response) {
          self.ip = response.data.ip
          self.get_default()
        })
      })
    },
    save_filter() {
      var self = this
      var filter = {}
      filter.branch = self.branch
      localStorage.setItem("Dashboard", JSON.stringify(filter))
    },
    get_filter() {
      var self = this
      if(localStorage.getItem("Dashboard")){
        var filter = JSON.parse(localStorage.getItem("Dashboard"))
        self.branch = filter.branch
      }else{
        self.branch = self.branch_list[0].branch
      }
    },
    get_default() {
      var self = this
      var index = -1
      var ipArray = self.ip.split(".")
        if(ipArray.length > 2){
          if(ipArray[2] == '0' || ipArray[2] == '3'){
            index = self.branch_list.findIndex(el => el.branch == 'TST')
          }else if(ipArray[2] == '8' || ipArray[2] == '1'){
            index = self.branch_list.findIndex(el => el.branch == 'ST')
          }else if(ipArray[2] == '99'){
            index = self.branch_list.findIndex(el => el.branch == 'CWB')
          }
          if(index >= 0){
            self.branch = self.branch_list[index].branch
          }
      }
    },
  },
  watch: {
    branch: function () {
      //self.save_filter()
	  }
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>